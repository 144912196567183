"use client";

import { Button } from "@/components/ui/button";
import { usePricingStore } from "@/store/pricing-store";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";

interface PricingBarProps {}

const PricingBar = ({}: PricingBarProps) => {
  const { calculateTotal, isYearly } = usePricingStore();

  const pathname = usePathname();

  const total = calculateTotal();

  console.log(total);
  

  if (!pathname.includes("/pricing")) return null;

  return (
    <div
      className={`border-t  bg-white w-[100vw]
           sticky bottom-0 left-0 right-0 z-10 transition-all duration-300 ease-in-out ${
             total !== 0 ? "opacity-100 translate-y-0" : "opacity-0 translate-y-full"
           }`}
    >
      <div className="flex items-center justify-between p-4 mx-auto text-black max-w-7xl">
        <p className="hidden font-semibold md:block">Need help ?</p>

        <div className="flex items-center justify-between w-full gap-2 md:w-auto">
          <div className="pb-2">
            <p className="p-0  space-x-1 leading-[5px]">
              <span className="text-xl font-semibold">£0</span>
              <span className="text-lg">now</span>
            </p>
            <p className="p-0 text-sm leading-[5px]">
              then £{total}/{isYearly ? "year" : "mo"}
            </p>
          </div>

          <Link href="/auth/signup" className="">
            <Button variant="secondary" className="">
              Continue
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PricingBar;
