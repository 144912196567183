// pricing-store.ts
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Plan, PlanCartItem } from "@/types/plan";

interface PricingState {
  planCartItems: PlanCartItem[];
  isYearly: boolean;
  plans: Plan[] | null;
  addOrUpdatePlanCartItem: (item: PlanCartItem) => void;
  removePlanCartItem: (planId: string) => void;
  updatePlanCartItemQuantity: (planId: string, type: UpdateType) => void;
  setIsYearly: (isYearly: boolean) => void;
  setPlans: (plans: Plan[]) => void;
  calculateTotal: () => number;
}

export const usePricingStore = create<PricingState>()(
  persist(
    (set, get) => ({
      planCartItems: [],
      isYearly: false,
      plans: null,
      addOrUpdatePlanCartItem: (item) => {
        set((state) => {
          const existingItemIndex = state.planCartItems.findIndex((i) => i.planId === item.planId);
          if (existingItemIndex > -1) {
            const updatedItems = [...state.planCartItems];
            updatedItems[existingItemIndex].quantity = (
              parseInt(updatedItems[existingItemIndex].quantity) + 1
            ).toString();
            return { planCartItems: updatedItems };
          } else {
            return { planCartItems: [...state.planCartItems, item] };
          }
        });
      },
      removePlanCartItem: (planId) => {
        set((state) => ({
          planCartItems: state.planCartItems.filter((i) => i.planId !== planId),
        }));
      },
      updatePlanCartItemQuantity: (planId, type) => {
        set((state) => {
          const updatedItems = state.planCartItems.map((item) => {
            if (item.planId === planId) {
              const currentQuantity = parseInt(item.quantity);
              if (type === UpdateType.Increment) {
                return { ...item, quantity: (currentQuantity + 1).toString() };
              } else if (type === UpdateType.Decrement && currentQuantity > 1) {
                return { ...item, quantity: (currentQuantity - 1).toString() };
              }
            }
            return item;
          });
          return { planCartItems: updatedItems };
        });
      },
      setIsYearly: (isYearly) => {
        set((state) => {
          const updatedItems = state.planCartItems.map((item) => {
            const plan = state.plans?.find((p) => p.id === item.planId);
            if (plan) {
              const newPrice = plan.prices.find((p) => p.interval === (isYearly ? "year" : "month"));
              if (newPrice) {
                return { ...item, stripePriceId: newPrice.stripePriceId };
              }
            }
            return item;
          });

          console.log("updatedItems", updatedItems);

          return { isYearly, planCartItems: updatedItems };
        });
      },
      setPlans: (plans) => set({ plans }),
      calculateTotal: () => {
        const { planCartItems, isYearly, plans } = get();
        return planCartItems.reduce((total, item) => {
          const plan = plans?.find((p) => p.id === item.planId);
          if (plan) {
            const price = plan.prices.find((p) => p.interval === (isYearly ? "year" : "month"));
            if (price) {
              return total + (price.amount / 100) * parseInt(item.quantity);
            }
          }
          return total;
        }, 0);
      },
    }),
    {
      name: "pricing-state",
    }
  )
);

export enum UpdateType {
  Increment,
  Decrement,
}
